import { takeEvery } from 'redux-saga/effects'
import {
  authConstants,
  workspaceConstants,
  repositoryConstants,
  userConstants
} from '../constants'
import {
  loginGithubSaga,
  loginGoogleSaga,
  loginMicrosoftSaga,
  validateAuthSaga,
  logoutUserSaga
} from './auth.sagas'
import { workspaceLoadSaga } from './workspace.sagas'
import { repositoryLoadSaga } from './repository.sagas'
import { userLoadSaga } from './user.sagas'

export function* watchAuth() {
  yield takeEvery(authConstants.AUTH_LOGIN_GOOGLE, loginGoogleSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_GITHUB, loginGithubSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_MICROSOFT, loginMicrosoftSaga)
  yield takeEvery(authConstants.AUTH_VALIDATE, validateAuthSaga)
  yield takeEvery(authConstants.AUTH_LOGOUT, logoutUserSaga)
}

export function* watchWorkspace() {
  yield takeEvery(workspaceConstants.WORKSPACE_LOAD, workspaceLoadSaga)
}

export function* watchRepository() {
  yield takeEvery(repositoryConstants.REPOSITORY_LOAD, repositoryLoadSaga)
}

export function* watchUser() {
  yield takeEvery(userConstants.USER_LOAD, userLoadSaga)
}
