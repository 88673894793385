import { combineReducers } from 'redux'

import auth from './auth.reducer'
import redirect from './redirect.reducer'
import notify from './notify.reducer'
import repository from './repository.reducer'
import workspace from './workspace.reducer'
import user from './user.reducer'

const rootReducer = combineReducers({
  auth,
  redirect,
  notify,
  repository,
  workspace,
  user
})

export default rootReducer
