import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  workspaceLoadSuccess,
  workspaceLoadFailure,
  addNotification
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* workspaceLoadSaga(): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.get(uris.workspace)
    yield put(workspaceLoadSuccess(data))
  } catch (error: any) {
    yield put(workspaceLoadFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
