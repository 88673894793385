import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  repositoryLoadSuccess,
  repositoryLoadFailure,
  addNotification
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* repositoryLoadSaga(action: any): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.get(
      `${uris.repository}/${action.payload}`
    )
    yield put(repositoryLoadSuccess(data))
  } catch (error: any) {
    yield put(repositoryLoadFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
