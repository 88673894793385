import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import { store } from './redux/store'
import { redirect } from './redux/actions'

import uris from './uris'
import { uiConstants } from './constants'

const onRequest = (config: AxiosRequestConfig): any => {
  config.headers = {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
    ...(config.headers || {})
  }

  config.headers.Authorization = store.getState().auth?.me?.accessToken ?? ''
  config.headers.uid = store.getState().auth?.me?.uid ?? ''

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (!error.response && !error.message) {
    const err = {
      response: {
        data: {
          message: uiConstants.messages.network_error,
          statusCode: 500
        }
      }
    }
    return Promise.reject(err)
  } else if (error.response?.status === 401) {
    // store.dispatch(userReset())
    store.dispatch(redirect('/'))
  }
  return Promise.reject(error)
}

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.defaults.baseURL = uris.apiBase
  axiosInstance.defaults.timeout = 30000

  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}

export default setupInterceptorsTo(axios)
