let apiBaseUrl = 'https://api.rott.dev/'

if (process.env.NODE_ENV === 'development') {
  apiBaseUrl = 'http://localhost:8080/'
}

const uris = {
  apiBase: apiBaseUrl + (apiBaseUrl[apiBaseUrl.length - 1] === '/' ? '' : '/'),
  auth: '/auth',
  workspace: '/workspace',
  repository: '/repository',
  rule: '/rule',
  user: '/user'
}

export default uris
