import { workspaceConstants } from '../constants'

const initialState = {
  loading: false,
  init: false,
  error: null,
  list: null,
  current: null
}

export default function workspace(state = initialState, action: any) {
  switch (action.type) {
    case workspaceConstants.WORKSPACE_LOAD:
      return {
        ...state,
        loading: true,
        init: true
      }
    case workspaceConstants.WORKSPACE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list
      }
    case workspaceConstants.WORKSPACE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case workspaceConstants.WORKSPACE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
