import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBVcFlsdfcPMCNaPywjni2M7VE0FTjjQ5E',
  authDomain: 'rott-dev-e7d4d.firebaseapp.com',
  projectId: 'rott-dev-e7d4d',
  storageBucket: 'rott-dev-e7d4d.appspot.com',
  messagingSenderId: '767215129164',
  appId: '1:767215129164:web:b306f1d6400e6e3e701ae9',
  measurementId: 'G-MES6EL6MSP'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
