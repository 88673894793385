const errorMessage = (error: any) => {
  try {
    const e = error.response?.data?.message ?? error.message ?? error
    if (!e) {
      throw new Error('Generic Error')
    }
    return e
  } catch {
    return 'Generic Error'
  }
}

const sort = (list: [], property: any, asc = true) => {
  if (!list) return []

  let v1 = 1
  let v2 = -1
  if (!asc) {
    v1 = -1
    v2 = 1
  }

  return [...list].sort((a: any, b: any) =>
    a[property] > b[property] ? v1 : a[property] < b[property] ? v2 : 0
  )
}

export const severityIcon = (s: string) => {
  switch (s.toLowerCase()) {
    case 'critical':
      return 'fa-solid fa-exclamation-triangle'
    case 'high':
      return 'fa-solid fa-exclamation'
    case 'medium':
      return 'fa-solid fa-info'
    case 'low':
      return 'fa-solid fa-minus'
    default:
      return 'fa-solid fa-info'
  }
}

export const severityColor = (s: string) => {
  switch (s.toLowerCase()) {
    case 'critical':
      return 'Red'
    case 'high':
      return 'Orange'
    case 'medium':
      return 'Yellow'
    case 'low':
      return 'Green'
    default:
      return 'Blue'
  }
}

export const uiHelper = {
  errorMessage,
  sort,
  severityIcon,
  severityColor
}
