import { repositoryConstants } from '../constants'

export const repositoryLoad = (payload: string) => {
  return {
    type: repositoryConstants.REPOSITORY_LOAD,
    payload
  }
}

export const repositoryLoadSuccess = (payload: any) => {
  return {
    type: repositoryConstants.REPOSITORY_LOAD_SUCCESS,
    payload
  }
}

export const repositoryLoadFailure = (payload: Error) => {
  return {
    type: repositoryConstants.REPOSITORY_LOAD_FAILURE,
    payload
  }
}

export const repositoryReset = () => {
  return {
    type: repositoryConstants.REPOSITORY_RESET
  }
}
