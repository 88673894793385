import { userConstants } from '../constants'

export const userLoad = () => {
  return {
    type: userConstants.USER_LOAD
  }
}

export const userLoadSuccess = (payload: any) => {
  return {
    type: userConstants.USER_LOAD_SUCCESS,
    payload
  }
}

export const userLoadFailure = (payload: Error) => {
  return {
    type: userConstants.USER_LOAD_FAILURE,
    payload
  }
}

export const userReset = () => {
  return {
    type: userConstants.USER_RESET
  }
}
