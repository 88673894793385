import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import { userLoadSuccess, userLoadFailure, addNotification } from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* userLoadSaga(): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.get(uris.user)
    yield put(userLoadSuccess(data))
  } catch (error: any) {
    yield put(userLoadFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
