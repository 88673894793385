import { workspaceConstants } from '../constants'

export const workspaceLoad = () => {
  return {
    type: workspaceConstants.WORKSPACE_LOAD
  }
}

export const workspaceLoadSuccess = (payload: any) => {
  return {
    type: workspaceConstants.WORKSPACE_LOAD_SUCCESS,
    payload
  }
}

export const workspaceLoadFailure = (payload: Error) => {
  return {
    type: workspaceConstants.WORKSPACE_LOAD_FAILURE,
    payload
  }
}

export const workspaceReset = () => {
  return {
    type: workspaceConstants.WORKSPACE_RESET
  }
}
