export const uiConstants = {
  messages: {
    network_error: 'Network error',
    repository_scan_success: 'Repository scan success'
  },
  notification: {
    info: 'Info',
    error: 'Error',
    warning: 'Warning',
    success: 'Success'
  },
  menu: [
    {
      icon: 'fa-brands fa-git-alt',
      to: '/dashboard',
      label: 'Dashboard'
    },
    {
      icon: 'fa-solid fa-chart-simple',
      to: '/analytics',
      label: 'Analytics'
    },
    {
      icon: 'fa-solid fa-gears',
      to: '/settings',
      label: 'Settings'
    }
  ],
  placeholders: {
    search_dashboard: 'Filter by organization, repository, or commit'
  },
  localStorage: {
    workspace: 'workspace'
  }
}
